import { ClientHookOptions, useVahakQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import API from "@vahak/core/dist/_services/const";
import { LorryMarketParam } from "./type";
import { LorryListResponse } from "../commonType/type";
import { generateUrlParamsFromArray } from "@vahak/core/dist/methods/generateUrlParamsFromArray";
import { generateUrlParamsFromObject } from "@vahak/core/dist/methods/generateUrlParamsFromObject";
import queryNames from "../../queryNames";

/**
 * Need to get rid of LorryMarketDetails (type)
 */

/**
 * Lorry market v2 api
 * @param param0
 * @param options
 * @returns
 */

export const useGetLorryMarketV2 = (
    {
        page,
        c_i,
        lorry_type_id,
        city_id,
        state_id,
        destination_city_id,
        destination_state_id,
        capacity_string,
        l,
        preloads,
        vehicle_verified_only,
        connections_only,
        lorry_type_counts,
        membership_user_filter,
        payment_linked_only,
        lorryId,
        destination_place_id,
        source_place_id
    }: LorryMarketParam,
    options: ClientHookOptions<LorryListResponse, unknown, LorryListResponse["data"]>
) => {
    const { GET } = useVahakHttpClient();

    const payload = {
        page,
        c_i,
        lorry_type_id,
        city_id,
        state_id,
        destination_city_id,
        destination_state_id,
        capacity_string,
        l,
        vehicle_verified_only,
        connections_only,
        lorry_type_counts,
        membership_user_filter,
        payment_linked_only,
        lorryId,
        destination_place_id,
        source_place_id
    };
    const urlString = generateUrlParamsFromObject(payload);
    let idString = lorryId && generateUrlParamsFromArray(lorryId, "id");
    let preloadsString = preloads && generateUrlParamsFromArray(preloads, "preloads");

    const getLorryMarketV2Data = () => {
        return GET<LorryListResponse>(
            `${API.LORRY_MARKET_V2}${idString ? "&" + idString : ""}${urlString}${
                preloadsString ? "&" + preloadsString : ""
            }`
        );
    };

    /**
     * If you don't know the error type pass unknown
     */
    return useVahakQuery<LorryListResponse, unknown, LorryListResponse["data"]>(
        [queryNames.getLorryMarketV2],
        getLorryMarketV2Data,
        {
            ...options,
            select: (data) => data.data
        }
    );
};
